.image-background {
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    align-items: center;
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .add-button {
    background-color: #f3ce09;
    padding: 10px 20px;
    border-radius: 5px;
    font-weight: bold;
    border: none;
    cursor: pointer;
  }
  
  .container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
  }
  
  .inner-box {
    text-align: center;
  }
  
  .title {
    font-size: 34px;
    color: #ffd700;
    font-weight: bold;
  }
  
  .price {
    font-size: 32px;
    color: #ffd700;
  }
  
  .box {
    background-color: rgba(0, 0, 0, 0.5);
    width: 300px;
    height: 200px;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .image2 {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    padding: 20px;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
  }
  
  .logo {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  
  .box-title {
    margin-left: 10px;
    color: #ffd700;
  }
  
  .exchange-rate {
    color: #fff;
    font-size: 14px;
  }
  
  .sell-button {
    background-color: hsl(0, 85%, 63%);
    padding: 10px 25px;
    border-radius: 20px;
    border: none;
    color: #fff;
    font-weight: bold;
    cursor: pointer;
  }
  
  .submit-button {
    background-color: #f3ce09;
    padding: 10px 25px;
    border-radius: 5px;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .input {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
  }
  
  .footer {
    text-align: center;
    margin-top: 20px;
  }
  
  .terms-container {
    display: flex;
    align-items: center;
  }
  
  .footer-text {
    margin-left: 5px;
    color: #fff;
  }
  
  .close-button {
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  