.outer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: url("../assets/theme1.jpg") no-repeat center center/cover;
  }
  
  .login-container {
    background-color: rgba(255, 255, 255, 0.9);
    padding: 30px;
    border-radius: 8px;
    width: 100%;
    max-width: 400px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .input-container {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  
  .input-container input {
    border: none;
    outline: none;
    flex-grow: 1;
    padding: 8px;
  }
  
  .material-icons {
    color: #595716;
    margin-right: 10px;
  }
  
  button {
    background-color: #ed2f2f;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    width: 100%;
  }
  
  button:hover {
    background-color: #d42c2c;
  }
  
  .error-text {
    color: red;
    margin-top: 10px;
  }
  
  .link-text {
    color: blue;
    cursor: pointer;
  }
  
  .loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.7);
  }
  
  .loader {
    border: 5px solid #f3f3f3;
    border-radius: 50%;
    border-top: 5px solid #3498db;
    width: 40px;
    height: 40px;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  